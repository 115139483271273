<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | ${defaultTitle}` : `${defaultTitle}`
    }}</template>
  </metainfo>
  <navbar />
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
    <a href="https://wa.me/+628111955165" class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
    </a>
  <router-view />
  <FooterBar />
</template>
<script>
import "./assets/js/owl.carousel.min.js";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/style.css";
import "./assets/css/skeleton.scss";
import "./assets/css/navbar-footer.css";
import "./assets/css/global-style.css";
import "./assets/css/owl.theme.css";
import { useMeta } from "vue-meta";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import FooterBar from "./components/FooterBar.vue";
import Navbar from "./components/NavigationBar.vue";

export default {
  name: "App",
  components: {
    Navbar,
    FooterBar,
  },
  setup() {
    useMeta({
      title: "",
      test: "asdasd",
    });
    const toast = useToast();
    const store = useStore();
    return { toast };
  },
  data() {
    return {
      defaultTitle: "Aplikasi Konsultasi dengan Psikolog, Coach, Konsultan - Teman Curhat",
    };
  },
  methods: {},
  mounted() {
    this.$store.dispatch("loadAgentType");
    this.$store.dispatch("isLogin");
  },
};
</script>
<style lang="scss" scoped>
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}

@font-face {
  font-family: SfProBold;
  src: url("./assets/fonts/pro/SF-Pro-Display-Bold.otf");
}
@font-face {
  font-family: SfProMedium;
  src: url("./assets/fonts/pro/SF-Pro-Display-Medium.otf");
}
@font-face {
  font-family: SfProRegular;
  src: url("./assets/fonts/pro/SF-Pro-Display-Regular.otf");
}
@font-face {
  font-family: SfProSemibold;
  src: url("./assets/fonts/pro/SF-Pro-Display-Semibold.otf");
}
</style>

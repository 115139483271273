<template>
  <div class="container-fluid p-0">
    <!-- <div class="container"> -->
    <nav class="navbar mynav navbar-expand-lg w-100" ref="mynav" v-on:scroll="handleScroll">
      <div class="container">
        <a class="navbar-brand" role="button" @click="$router.push('/')">
          <img src="../assets/img/Icon/LogoTm.svg" class="h-100" alt="" />
        </a>

        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav d-flex justify-content-end align-items-center w-100">
            <router-link to="/" active-class="active" class="nav-link nav-item me-xl-4 me-3">Home</router-link>
            <!-- <router-link to="/event" active-class="active" class="nav-link nav-item me-xl-4 me-3">Event</router-link> -->
            <router-link to="/company" active-class="active" class="nav-link nav-item me-xl-4 me-3">For Company</router-link>
            <!-- <router-link to="/agent" active-class="active" class="nav-link nav-item me-xl-4 me-3">Agent</router-link> -->
            <router-link to="/blog" active-class="active" class="nav-link nav-item me-xl-4 me-3">Blog</router-link>
            <router-link to="/login" active-class="active" class="me-xl-4 me-xl btn-login" v-if="isLoggedIn === false">Masuk</router-link>
            <router-link to="/profile/user" active-class="active" class="me-xl-4 me-xl btn-login" v-if="isLoggedIn === true">Hi, {{userName}}</router-link>
          </div>
        </div>

        <button
          class="navbar-toggler p-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <label for="check">
            <input type="checkbox" id="check" />
            <span></span>
            <span></span>
            <span></span>
          </label>
        </button>
      </div>
    </nav>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  name: "Navbar",
  components: {},
  data() {
    return {
      agentTest: [
        {
          id: 1,
          name: 'Test',
        }
      ],
    };
  },
  computed: {
    agentType() {
      return this.$store.state.agentType;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    userName() {
      return this.$store.state.userProfile?.name;
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      if (window.pageYOffset <= 0) {
        this.$refs.mynav.style.backgroundColor = "transparent";
        this.$refs.mynav.style.boxShadow = "0px 4px 24px rgba(105, 105, 105, 0)";
      } else if (window.pageYOffset > 0) {
        this.$refs.mynav.style.backgroundColor = "var(--text-color-light)";
        this.$refs.mynav.style.boxShadow = "0px 4px 24px rgba(105, 105, 105, 0.1)";
      }
    },
  },
  mounted() {
    
  },
};
</script>

import axios from "axios";
import store from "@/store";

const API = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

API.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${store.state.userProfile?.remember_token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export default API;

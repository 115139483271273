import { createApp } from 'vue';
import Toast from 'vue-toastification';
import App from './App.vue';
import router from './router';
import store from './store';
import { createMetaManager } from 'vue-meta';
import 'vue-toastification/dist/index.css';

const toastOptions = {
    position: 'top-center',
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
  };

createApp(App)
    .use(store)
    .use(router)
    .use(Toast, toastOptions)
    .use(createMetaManager())
    .mount('#app');
import { createRouter, createWebHistory   } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue'),  
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('@/views/CompanyView.vue'),
  },
  {
    path: '/premium',
    name: 'Kelas Premium',
    component: () => import('@/views/KelasPremiumView.vue'),
  },
  {
    path: '/event',
    name: 'Event',
    component: () => import('@/views/EventListView.vue'),
  },
  {
    path: '/event/:id',
    name: 'Event Detail',
    component: () => import('@/views/EventDetailView.vue'),
  },
  {
    path: '/agent',
    name: 'Agent',
    component: () => import('@/views/AgentListViewNew.vue'),
  },
  {
    path: '/agent/:cat',
    name: 'Agent Detail',
    component: () => import('@/views/AgentListView.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('@/views/BlogListView.vue'),
  },
  {
    path: '/blog/:slug',
    name: 'Blog Detail',
    component: () => import('@/views/BlogDetailView.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/AboutView.vue'),
  },
  {
    path: '/:id',
    name: 'Agent Details',
    component: () => import('@/views/AgentDetailView.vue'),
  },
  {
    path: '/404',
    name: '404 Not Found',
    component: () => import('@/views/NotFound.vue'),
  },
  {
    path: '/tnc',
    name: 'Terms And Condition',
    component: () => import('@/views/TermsCondition.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('@/views/PrivacyPolicy.vue'),
  },
  {
    path: '/agent-registration',
    name: 'Agent Registration',
    component: () => import('@/views/AgentRegistration.vue'),
  },
  {
    path: '/konseling',
    name: 'Info Konseling',
    component: () => import('@/views/InfoKonseling.vue'),
  },
  {
    path: '/financial',
    name: 'Info Financial',
    component: () => import('@/views/InfoFinancial.vue'),
  },
  {
    path: '/coach',
    name: 'Info Coach',
    component: () => import('@/views/InfoCoach.vue'),
  },
  {
    path: '/event-complete',
    name: 'Event Registration Complete',
    component: () => import('@/views/EventCompletePayment.vue'),
  },
  {
    path: '/agent-complete',
    name: 'Agent Registration Complete',
    component: () => import('@/views/RegistrationComplete.vue'),
  },
  {
    path: '/login',
    name: 'User Login',
    component: () => import('@/views/UserLogin.vue'),
  },
  {
    path: '/register',
    name: 'User Register',
    component: () => import('@/views/UserRegister.vue'),
  },
  {
    path: '/profile/:menu',
    name: 'User Profile',
    component: () => import('@/views/UserProfile.vue'),
  },
];

const router = createRouter({
  history: createWebHistory() ,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

export default router;

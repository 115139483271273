<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-12">
          <div>
            <img src="../assets/img/Icon/LogoTm.svg" class="d-block logo" alt="" />
          </div>
          <div>
            <div>
              <ion-icon name="mail-outline" class="me-3 mt-md-3 mt-1"></ion-icon>
              <a href="mailto:info@temancurhat.app" style="text-decoration:none; color:inherit"><span>info@temancurhat.app</span></a>
            </div>
            <div>
              <ion-icon name="call-outline" class="me-3 mt-md-3 mt-1"></ion-icon
              ><a href="tel:+628111955165" style="text-decoration:none; color:inherit"><span>+62 8111 955 165</span></a>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-4 offset-lg-1 mt-4 mt-lg-0">
          <h4>Layanan Kami</h4>
          <ul class="list-unstyled">
            <li>
              <a role="button" @click="$router.push('/company')" class="text-decoration-none mt-md-3 mt-1 d-inline-block"
                >Teman Curhat for Company</a
              >
            </li>
            <li>
              <a role="button" @click="$router.push('/event')" class="text-decoration-none mt-md-3 mt-1 d-inline-block"
                >Event Training & Webinar</a
              >
            </li>
            <li>
              <a role="button" @click="$router.push('/blog')" class="text-decoration-none mt-md-3 mt-1 d-inline-block">Blog</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-2 col-md-4 mt-4 mt-lg-0">
          <h4>Pelajari</h4>
          <ul class="list-unstyled">
            <li>
              <a role="button" @click="$router.push('/tnc')" class="text-decoration-none mt-md-3 mt-1 d-inline-block"
                >Syarat dan Ketentuan</a
              >
            </li>
            <li>
              <a role="button" @click="$router.push('/privacy-policy')" class="text-decoration-none mt-md-3 mt-1 d-inline-block"
                >Kebijakan Privasi</a
              >
            </li>
            <li>
              <a href="#" class="text-decoration-none mt-md-3 mt-1 d-inline-block">Tentang Kami</a>
            </li>
            <li>
              <a href="#FAQ" class="text-decoration-none mt-md-3 mt-1 d-inline-block">F.A.Q</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-4 download-aplikasi mt-4 mt-lg-0">
          <h4>Download Aplikasi</h4>
          <div class="d-flex flex-column justify-content-between">
            <div
              class="w-50 d-flex justify-content-center shadow-sm align-items-center py-1 px-2 bg-light rounded-5 bg-white"
            >
              <a href="#"><img src="../assets/img/Icon/google_play_button.svg" class="w-100" alt="" /></a>
            </div>
            <div
              class="w-50 d-flex justify-content-center shadow-sm align-items-center py-1 px-2 bg-light mt-3 rounded-5 bg-white"
            >
              <a href="#"><img src="../assets/img/Icon/app_store_button.svg" class="w-100" alt="" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import "../assets/css/navbar-footer.css";

export default {
  name: "App",
  components: {},
  data() {},
  methods: {},
  mounted() {},
};
</script>

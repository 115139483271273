import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import API from '../apis';
import APIUSER from '../apiuser';
import dayjs from 'dayjs';

export default createStore({
  state: {
    agentType: [],
    userProfile: null,
    isLoggedIn: false,
    ticketCart: null,
  },
  mutations: {
    SET_AGENT_TYPE(state, payload) {
      state.agentType = payload;
    },
    SET_USER_PROFILE(state, payload) {
      state.userProfile= payload
    },
    SET_TICKET_CART(state, payload) {
      state.ticketCart = payload
    }
  },
  actions: {
    async loadAgentType({ commit }) {
      let agentType = [];
      try {
        const {
          data: { data },
        } = await API.get('agent-type-category?source=webapp');
        agentType = data;
        commit('SET_AGENT_TYPE', agentType);
      } catch(error) {
        console.log(error);
      }
    },
    isLogin () {
      if(this.state.userProfile) {
        this.state.isLoggedIn = true
      }else{
        this.state.isLoggedIn = false
      }
    },
    logout () {
      this.state.userProfile = null;
      isLogin();
    },
    dateConvert(raw, mode) {
      return dayjs(raw).format(mode);
    },
  },
  plugins: [createPersistedState()],
});